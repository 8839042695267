gsap.registerPlugin(CSSRulePlugin, Draggable, EasePack, PixiPlugin, EaselPlugin, ScrollToPlugin, CustomEase);
'use strict';
var EMA = EMA || {};

EMA.BUILD = (function() {
    return {
        init: function() {
            // Find all controller in page
            // Define template or content
            console.log("INIT");
            $('[data-control]').each(function(index, elem) {
                var data = $(elem).data(),
                    control = data.control.split(',');

                for (var i = 0; i < control.length; i++) {
                    var controller = control[i];
                    if (!EMA[controller]) {
                        return;
                    }

                    if (typeof EMA[controller] === 'function') {
                        var obj = new EMA[controller]();
                        obj.init(elem, data);
                    } else if (typeof EMA[controller] === 'object') {
                        EMA[controller].init(elem, data);
                    }
                }
            });

            // // Convert IMG to SVG source code inline
             this.svg('img.svg-convert');
            //
            // Load Lazy Images with animation
            this.lazyLoader();


        },
        templateEngine: function(html, options) {

            // Git : https://github.com/krasimir/absurd/blob/master/lib/processors/html/helpers/TemplateEngine.js

            var re = /<%(.+?)%>/g,
                reExp = /(^( )?(var|if|for|else|switch|case|break|{|}|;))(.*)?/g,
                code = 'with(obj) { var r=[];\n',
                cursor = 0,
                result, match;

            var add = function(line, js) {
                js ? (code += line.match(reExp) ? line + '\n' : 'r.push(' + line + ');\n') : (code += line !== '' ? 'r.push("' + line.replace(/"/g, '\\"') + '");\n' : '');
                return add;
            };

            while (match = re.exec(html)) {
                add(html.slice(cursor, match.index))(match[1], true);
                cursor = match.index + match[0].length;
            }

            add(html.substr(cursor, html.length - cursor));
            code = (code + 'return r.join(""); }').replace(/[\r\t\n]/g, ' ');

            try {
                result = new Function('obj', code).apply(options, [options]);
            } catch (err) {
                console.error("'" + err.message + "'", ' in \n\nCode:\n', code, '\n');
            }

            return result;
        },
        lazyLoader: function() {

            var bLazy = new Blazy({
                offset: 0,
                success: function(element) {

                    setTimeout(function() {
                        // We want to remove the loader gif now.
                        // First we find the parent container
                        // then we remove the "loading" class which holds the loader image
                        var parent = element.parentNode;
                        parent.className = parent.className.replace(/\bloading\b/, '');
                    }, 10);
                }
            });

            return bLazy;
        },
        svg: function(elem) {
            $(elem).each(function() {
                var $img = $(this),
                    imgID = $img.attr('id'),
                    imgClass = $img.attr('class'),
                    imgURL = $img.attr('src'),
                    imgWidth = $img.attr('width'),
                    imgHeight = $img.attr('height');

                $.get(imgURL, function(data) {
                    // Get the SVG tag, ignore the rest
                    var $svg = $(data).find('svg');

                    // Add replaced image's ID to the new SVG
                    if (typeof imgID !== 'undefined') {
                        $svg = $svg.attr('id', imgID);
                    }
                    // Add replaced image's classes to the new SVG
                    if (typeof imgClass !== 'undefined') {
                        $svg = $svg.attr('class', imgClass + ' replaced-svg');
                    }

                    // Add replaced image's classes to the new SVG
                    if (typeof imgWidth !== 'undefined') {
                        $svg = $svg.css('width', imgWidth);
                    }

                    // Add replaced image's classes to the new SVG
                    if (typeof imgHeight !== 'undefined') {
                        $svg = $svg.css('height', imgHeight);
                    }

                    // Remove any invalid XML tags as per http://validator.w3.org
                    $svg = $svg.removeAttr('xmlns:a');

                    // Replace image with new SVG
                    $img.replaceWith($svg);

                }, 'xml');

            });
        },
        viewportOfElement: function (element, fullyInView) {
          var pageTop = $(window).scrollTop();
          var pageBottom = pageTop + $(window).height();
          var elementTop = $(element).offset().top;
          var elementBottom = elementTop + $(element).height();

          if (fullyInView === true) {
              return ((pageTop < elementTop) && (pageBottom > elementBottom));
          } else {
              return ((elementTop <= pageBottom) && (elementBottom >= pageTop));
          }
        },
        resize: function(offset, element) {
            var scrollTop = Math.round($(window).scrollTop()),
                windowHeight = Math.round($(window).height()),
                windowWidth = Math.round($(window).width()),
                documentHeight = Math.round($(document).height()),
                elementTop = (element) ? Math.round($(element).offset().top) : null,
                elementHeight = (element) ? Math.round($(element).outerHeight(true)) : null,
                elementOffset = (elementTop) ? elementTop - Math.round(offset) : null,
                offsetDocument = (offset) ? documentHeight - Math.round(offset) : documentHeight,
                elementScrollBottom = (element) ? ((elementTop - scrollTop) + elementHeight) : null;

            return {
                scrollTop: scrollTop,
                windowHeight: windowHeight,
                windowWidth: windowWidth,
                documentHeight: documentHeight,
                ifBottom: scrollTop + windowHeight >= offsetDocument,
                elementTop: elementTop,
                elementOffset: elementOffset,
                elementHeight: elementHeight,
                elementScrollBottom: elementScrollBottom,
                elementTouchBottom: elementScrollBottom > windowHeight
            };
        }
    };
})();
EMA.HOME = (function() {

    var self;
    var header;
    var licenseKeyScope;
    var scrollOverflowResetKeyScope;
    return {
        init: function(elem) {
          self = this;

          $(window).resize(function() {
              self.resize();
          });

          self.resize();
          licenseKeyScope = licenseKey;
          scrollOverflowResetKeyScope = scrollOverflowResetKey;
          //console.log(licenseKey, scrollOverflowResetKey);
          // if(Foundation.MediaQuery.is('medium down')) {
          //   $('.section.categories').remove();
          // }
          if(Foundation.MediaQuery.is('medium up')) {
            $('#fullpage').fullpage({
              //options here
              licenseKey: licenseKey,
              scrollOverflowResetKey: scrollOverflowResetKey,
              scrollOverflowReset: true,
              scrollOverflow: true,
              navigation: false,
              touchSensitivity: 1,
              lockAnchors: true,
              fixedElements: ".section-background, .main-nav, .footer-container",
              // continuousVertical: true,
              afterLoad: function(origin, destination, direction){
                //console.log(origin, destination, direction);
                var loadedSection = this;
                if(origin.index == 2) {
                  $('.marquee .track').addClass('paused');
                }
                var elems = $(destination.item).find('.animation-do');
                //console.log(elems);
                for (var i = 0; i < elems.length; i++) {
                    //console.log(elems[i]);
                    elems[i].className = elems[i].className.replace('animation-do', 'element-animate')
                }
                self.resize();
              },
              onLeave: function(origin, destination, direction){
                // $('.section-background').removeClass (function (index, className) {
                //   return (className.match (/(^|\s)color-bg-\S+/g) || []).join(' ');
                // });
                // var bgClass = "color-bg-"+$(destination.item).data('bgcolor');
                // $('.section-background').addClass(bgClass);
                //
                if(destination.index == 0) {
                  EMA.HEADER.randomPlay();
                  EMA.NAV.hide();
                }
                if(destination.index > 0) {
                  EMA.NAV.showSmall();
                }
                if(destination.index == 2) {
                  $('.marquee .track').removeClass('paused');
                }
                var elems = $(origin.item).find('.element-animate');
                //console.log(elems);
                for (var i = 0; i < elems.length; i++) {
                    //console.log(elems[i]);
                    elems[i].className = elems[i].className.replace('element-animate', 'animation-do')
                }
              }
            });
          } else {
            $('.marquee .track').removeClass('paused');
          }

        	//methods
        	//$.fn.fullpage.setAllowScrolling(true);
        },resize: function() {
          // $(".main-nav").width($(window).height());
          // $(".main-nav").css("left", $(window).width()-$(".main-nav").height());

          // $('.container.full-height').each(function(){
          //   if($(this).height() < $(window).height()) {
          //     $(this).height($(window).height());
          //   }
          // })
          // $(".project-highlight .bottom-links").height($("footer").height())
          $('.orientation-left, .orientation-right').each(function(){
              $(this).width($(this).parent().parent().height());
          })
          $('.fp-scroller').css("min-height",$(window).height());
        }
      }
})();

EMA.PORTFOLIO = (function() {
  var self;
  var elem;
  var template;
  var structure;
  var initIndex = 0;
  return {
    init: function(el) {
      self = this;
      elem = el;

      template = $('.dynamic-category-container script[type="text/template"]')[0];
      structure = template.cloneNode(true).innerHTML;
      template.parentNode.removeChild(template);

      // $('#footer').hide();
      $(window).on("resize", function() {
          self.resize();
      });
      
     
      $(".portfolio-list .portfolio-list-item").on("mouseenter", function(){
        var i = $(this).parent().index();
        var image = $(this).data("background-image");
        // console.log(image);
        $(".portfolio-list-overimages .portfolio-list-overimages-item").eq(i).css("background-image", "url("+image+")")
        $(".portfolio-list-overimages .portfolio-list-overimages-item").eq(i).addClass("active");
      }).on("mouseleave", function(){
        $(".portfolio-list-overimages .portfolio-list-overimages-item").removeClass("active");
      })

      $(".portfolio-list-filter .filter a").click(function(){
        $(".portfolio-list-filter .filter a").removeClass("active")
        $(this).addClass("active")
        if($(this).data("filter-style") == "list") {
          self.setFilterStyleList()
        }
        if($(this).data("filter-style") == "grid") {
          self.setFilterStyleGrid();
          EMA.BUILD.lazyLoader();
        }
        if($(this).data("filter-style") == "dynamic") {
          self.setFilterStyleDynamic();
        }
      })

      $(".portfolio-dynamic .marquee-move-anchor").click(function(e){
        console.log("clickou");
        e.preventDefault();
        var id = $(this).data("category-id");
        self.setFilterStyleDynamicCategory(id, $(this).attr("id"));
        console.log($(this).attr("id"));
        //history.pushState(null,null,"#"+hash);
      })
      if(window.location.hash) {
        if($(window.location.hash).length >0 ) {
          var el = $(window.location.hash);
          var id = el.data("category-id");
          
          self.setFilterStyleDynamicCategory(id, window.location.hash);
        }
        else {
          self.setFilterStyleGrid();
        }
      } else {
        self.setFilterStyleGrid();
      }
      window.addEventListener("hashchange", function(){
        if(window.location.hash) {
          if($(window.location.hash).length >0 ) {
            var el = $(window.location.hash);
            var id = el.data("category-id");
            
            self.setFilterStyleDynamicCategory(id, window.location.hash);
          }
          else {
            console.log("aqui")
            self.setFilterStyleDynamic();
          }
        } 
        // else {
        //   self.setFilterStyleDynamic();
        //   console.log("aqui 2")
        // }
      }, false);
      
      // if(Foundation.MediaQuery.is('medium down')) {
      //   self.setFilterStyleGrid();
      // }
      
    },
    setFilterByHash: function(){
      
    },
    resize: function(elem) {
      $('.portfolio-list').css("min-height", $(window).height()-(($('footer').outerHeight()*2)+$(".portfolio-list-filter").outerHeight()+20));
      // $('.marquee-container').css("margin-top", $('.portfolio-list-filter').height());
      if(Foundation.MediaQuery.is('xxlarge down') && Foundation.MediaQuery.is('medium up')){
        $('.full-height').each(function(){
          
          var height = $(window).height()-($('.portfolio-list-filter').height()+$("#footer").height());
          $(this).height(height);
          $(this).css("max-height",height);
        })
      }
      
      //console.log($(".portfolio-type-container"), window.innerHeight);
      $(".portfolio-type-container").css("min-height", window.innerHeight);
      $('.footer-container').show();
      // var y = ($('.full-height').height() - $('.marquee-container').outerHeight()) / 2;
      
      // $('.marquee-container').css("top", y);
      EMA.FOOTER.positionFooter();
    },
    setFilterStyleGrid: function(){
      console.log("setFilterStyleGrid");
      $(".portfolio-list-filter .filter a").removeClass("active");
      $(".filter-item.grid").addClass("active");
      $(".portfolio-list").hide();
      $(".portfolio-grid").show();
      $(".portfolio-dynamic").hide();
      $("footer").removeClass("white");
      $(".portfolio-list-filter").addClass("white");
      $(".portfolio").addClass("color-bg-white");
      $(".portfolio").removeClass("color-bg-black");
      $('.marquee .track').addClass('paused');
      $('.dynamic-category').hide();
      if(initIndex != 0) {
        EMA.FOOTER.positionFooter();
      }
      
      // EMA.BUILD.lazyLoader();
      
    },
    setFilterStyleList: function(){
      console.log("setFilterStyleList");
      $(".portfolio-list-filter .filter a").removeClass("active");
      $(".filter-item.list").addClass("active");
      $(".portfolio-list").show();
      $(".portfolio-grid").hide();
      $(".portfolio-dynamic").hide();
      $("footer").addClass("white");
      $(".portfolio-list-filter").removeClass("white");
      $(".portfolio").removeClass("color-bg-white");
      $(".portfolio").addClass("color-bg-black");
      $('.marquee .track').addClass('paused');
      $('.dynamic-category').hide();
      EMA.BUILD.lazyLoader();
      EMA.FOOTER.positionFooter();
      initIndex++;
    },
    setFilterStyleDynamic: function(){
      console.log("setFilterStyleDynamic");
      $(".portfolio-list-filter .filter a").removeClass("active");
      $(".filter-item.dynamic").addClass("active");
      $(".portfolio-list").hide();
      $(".portfolio-grid").hide();
      $(".portfolio-dynamic").show();
      $("footer").addClass("white");
      $(".portfolio-list-filter").removeClass("white");
      $(".portfolio").removeClass("color-bg-white");
      $(".portfolio").addClass("color-bg-black");
      $('.marquee .track').removeClass('paused');
      EMA.FOOTER.positionFooter();
      initIndex++;
    },
    setFilterStyleDynamicCategory: function(id, hash){
      var res = hash.replace("#", "");
      history.pushState(null,null,"#"+res);
      
      $(".portfolio-list-filter .filter a").removeClass("active");
      $(".filter-item.dynamic").addClass("active");
      $(".portfolio-list").hide();
      $(".portfolio-grid").hide();
      $(".portfolio-dynamic").fadeOut(function(){
        $('.dynamic-category').fadeIn(500);
      });
      $("footer").addClass("white");
      $(".portfolio-list-filter").removeClass("white");
      $(".portfolio").removeClass("color-bg-white");
      $(".portfolio").addClass("color-bg-black");
      $('.marquee .track').addClass('paused');
      
      $.ajax({
        url: '/frontend/engine/engine.php',
        type: 'POST',
        dataType: 'json',
        data: $.param({
          'action': 'getDynamicChildrenPortfolio',
          'UID': id
        }),
        success: function(data, status) {
          $('.dynamic-category-container').html("");
         
          
          for (var i = 0; i < data.length; i++) {
            var templateBuild = EMA.BUILD.templateEngine(structure, {
              title: data[i].title,
              optional2: data[i].optional2,
              deeplink: data[i].deeplink,
              menuLabel: data[i].menuLabel,
              date: data[i].date.split("-")[0],
              image: data[i].files.imageFiles[0].file
            });
            $(templateBuild).appendTo('.dynamic-category-container'); 
          }
          if(!data.length) {
            $('.dynamic-category-container').html("<h2>"+$('.dynamic-category-container').data("noresults-label")+"</h2>")
          }
          // gsap.to(self, .5,{onComplete: self.resize})
          self.resize();
          EMA.FOOTER.positionFooter();
        },
        error: function(xhr, desc, err) {
          console.error('error: ', xhr);
        }
      });
    }
  }
})();

EMA.PORTFOLIO_ITEM = (function() {

    var self;
    var header;
    return {
        init: function(elem) {
          self = this;
          var speed = 3000;

          $('.owl-carousel').each(function(){
            if($(this).data('slider-speed')) {
              speed = $(this).data('slider-speed');
            }
            $(this).owlCarousel({
              loop:true,
              margin:0,
              mouseDrag: false,
              touchDrag: false,
              nav:false,
              items: 1,
              dots: false,
              autoplay: true,
              autoplayTimeout: speed,
              autoplayHoverPause: false,
              animateOut: 'fadeOut',
              animateIn: 'fadeIn',
              autoHeight:true
            });
          })
          $(window).on("resize", function() {
              self.resize();
              
          });
          if(Foundation.MediaQuery.is('small down')) {
            
            var readMoreOptions = { startOpen: false, collapsedHeight: 100, moreLink: '<a href="javascript:;" class="read-more">Read more<span class="plus"></span></a>', lessLink: '<a href="javascript:;" class="read-more less">Read less<span class="plus"></span></a>'}
            // $('.project-description, .project-title').readmore(readMoreOptions);
            new Readmore('.project-description', readMoreOptions);
            var readMoreTitleHeight =  $('.project-title h1').outerHeight() + $('.project-title p:eq(0)').outerHeight();
            readMoreOptions.collapsedHeight = readMoreTitleHeight
            // var readMore = $('.project-title').readmore(readMoreOptions);
            new Readmore('.project-title', readMoreOptions);

            // RESPONSIVE IMAGES
            $(".interchangeBackground").each(function(){
              var responsiveImage = $(this).data("responsive-image");
              
              $(this).css("background-image", "url("+responsiveImage+") !important");
              $(this).attr("data-src", responsiveImage);
              $(this).find(".interchange").attr("src", responsiveImage);
              $(this).find(".interchange").attr("data-src", responsiveImage);
            })
            $(window).on("load", function(){
              $(".project-description-container").toggleClass("expand");

              $("section").each(function(i){
                if(i == 1) {
                  if($(this).find("video").length) {
                    $("section:eq(2)").find(".animation-do").removeClass("animation-do").addClass("element-animate");
                  }
                }
              })
            })
            
            
          }
          self.resize();
          EMA.NAV.showSmall();

          $(elem).find('.image-link').magnificPopup({
            type: 'image',
            mainClass: 'mfp-with-zoom', // this class is for CSS animation below
            closeOnContentClick: true,
            showCloseBtn: false,

            zoom: {
              enabled: true, // By default it's false, so don't forget to enable it

              duration: 200, // duration of the effect, in milliseconds
              easing: 'ease-out', // CSS transition easing function

              // The "opener" function should return the element from which popup will be zoomed in
              // and to which popup will be scaled down
              // By defailt it looks for an image tag:
              opener: function(openerElement) {
                // openerElement is the element on which popup was initialized, in this case its <a> tag
                // you don't need to add "opener" option if this code matches your needs, it's defailt one.
                return openerElement.is('img') ? openerElement : openerElement.find('img');
              }
            }
          })

        	//methods
        	//$.fn.fullpage.setAllowScrolling(true);
        },resize: function() {
          // $(".main-nav").width($(window).height());
          // $(".main-nav").css("left", $(window).width()-$(".main-nav").height());
          // console.log(Foundation.MediaQuery.current, Foundation.MediaQuery.is('xxlarge down'), Foundation.MediaQuery.is('medium up'), Foundation.MediaQuery.is('xxlarge down') && Foundation.MediaQuery.is('medium up'));
          if(Foundation.MediaQuery.is('xxlarge down') && Foundation.MediaQuery.is('medium up')){
            $('.full-height, .full-height .row, .full-height .row .columns').each(function(){
              self.setFullHeightWithin(this);
              if($(this).find("video").length) {
                
                self.manageVideoAudios($(this).find("video").attr('id'));
              }
            })
            $('.half-height').each(function(){
              self.setHalfHeightWithin(this);
              if($(this).find("video").length) {
                
                self.manageVideoAudios($(this).find("video").attr('id'));
              }
            })
          } else {
            $('.half-height, .full-height').each(function(){
              if($(this).find("video").length) {
                var el = $(this);
                var newHeight;
                var vidID = $(this).find("video").attr('id');
                var vid = document.getElementById(vidID);
                vid.addEventListener("loadedmetadata", function (e) {
                  var width = this.videoWidth,
                      height = this.videoHeight;
                      newHeight = self.getRatioHeight(width, height);
                      el.width($(window).width());
                      el.css("min-height",newHeight);
                      el.find('*:not(.icon-icon-play, .columns.small-12.medium-6.pr-0.pl-0, .project-description, .project-description .columns, p, .video-controls-container, .icon, .read-more, .plus)').each(function() {
                        $(this).width($(window).width());
                        $(this).css("min-height", newHeight);
                    });
                }, false );
                self.manageVideoAudios($(this).find("video").attr('id'));
              }
            })
            $('.half-height').each(function(){
              // self.setHalfHeightWithin(this);
              // if($(this).find('.owl-item').length) {
              //   $(this).css("max-height", $(this).find('.owl-item').height());
              //   $(this).height($(this).find('.owl-item').height());
              //   $(this).css("max-height", $(this).find('.owl-item').height());
              // }
            })
          }
          $('.floating-element').each(function(){
            $(this).css("line-height", $(this).height()+"px");
          })
          
        },
        manageVideoAudios: function(videoID) {
          
          var vidID = videoID;
          var vid = document.getElementById(vidID);
          function hideControls()
          {
            var timer;
            $(document).on("mousemove", function() {
                if (timer) {
                  clearTimeout(timer);
                  timer = 0;
                }
                $(vid).parent().find(".video-controls-container").fadeIn();
                $(vid).parent().css("cursor", "pointer");
                timer = setTimeout(function() {
                  if(!paused) {
                    $(vid).parent().find(".video-controls-container").fadeOut();
                    $(vid).parent().css("cursor", "none");
                  }
                }, 1500)
            })
          }
          var paused = true;
          var hasAudioTrack = $(vid).data('audio');
          vid.addEventListener("loadeddata", function (e) {
            
            if ( !isNaN(hasAudioTrack) && hasAudioTrack >0 ) {
              console.log("audio", hasAudioTrack);
              this.onplaying = function() {
               console.log("is playing");
               paused = false;
               $(this).parent().find('.video-controls-container .icon-icon-play').removeClass("icon-icon-play" ).addClass("icon-icon-pause");
               var image = $(this).parent().find('.overlay-transparent-image');
               //gsap.to(window, {duration: 2, scrollTo:target, ease: Back.easeInOut});
               gsap.to(image, .2, {opacity: 0, ease: Quad.easeOut});
              };
              this.onended = function() {
                console.log("is onended");
                this.load();
                $(this).parent().find('.video-controls-container .icon-icon-pause').removeClass("icon-icon-pause" ).addClass("icon-icon-play");
                var image = $(this).parent().find('.overlay-transparent-image');
                gsap.to(image, .2, {opacity: 1, ease: Quad.easeOut});

                paused = true;
              };
              this.onpause = function() {
                console.log("is onpause");
                $(this).parent().find('.video-controls-container .icon-icon-pause').removeClass("icon-icon-pause" ).addClass("icon-icon-play");
                // $(this).parent().find('.overlay-transparent-image').fadeIn();
                var image = $(this).parent().find('.overlay-transparent-image');
                gsap.to(image, .2, {opacity: 1, ease: Quad.easeOut});
                paused = true;
              };
              // console.log("audio track detected",vidID);
              $(this).parent().find(".video-controls-container").show()
              $(this).parent().css("cursor", "pointer");
              $(this).parent().off("click");
              this.muted = false;
              this.loop = false;
              this.playsinline = false;
              $(this).removeAttr("playsinline");
              $(this).removeAttr("muted");
              // .webkitEnterFullScreen();
              this.pause();
              $(this).parent().on('click', function(){
                
                $(vid).parent().find(".video-controls-container").fadeIn();
                hideControls();
                

                if(paused) {
                  self.pauseAllVideos();
                  vid.play();
                } else {
                  vid.pause();
                }
              })
            }
           
            
          }, false)
        },
        pauseAllVideos: function() {
          $('.video-fit-fullscreen').each(function(){
            var vidID = $(this).find("video").attr("id");
            var vid = document.getElementById(vidID);
            var hasAudioTrack = $(vid).data('audio');
            if ( !isNaN(hasAudioTrack) && hasAudioTrack > 0 ) {
              vid.pause();
            }
          })
        },
        setFullHeightWithin: function(el){
          $(el).height($(window).height());
          $(el).css("max-height", $(window).height());
          $(el).find('img:not(.overlay-transparent-image').css("visibility", "hidden");
          $(el).find('.owl-item img').css("visibility", "visible");
        },
        setHalfHeightWithin: function(el){
          // console.log("project-description-container", $(el).hasClass("project-description-container"));
          if(!$(el).hasClass("project-description-container")) {
            $(el).find(".row, .columns").each(function(){
              $(this).css("max-height", $(window).height());
              $(this).height($(window).height()/2);
              $(this).css("max-height", $(window).height()/2);
              $(this).find('img:not(.overlay-transparent-image').css("visibility", "hidden");
              $(this).find('.owl-item img').css("visibility", "visible");
            })
          }
          if(Foundation.MediaQuery.is('medium up')){
            // console.log($(el).find('.owl-item').length);
            if($(el).find('.owl-item').length) {
              $(el).find('.owl-item').each(function(){
                $(this).css("max-height", $(window).height()/2);
                $(this).height($(window).height()/2);
                $(this).find("img").height($(window).height()/2);
              })
            }
          }
        },
        getRatioHeight: function(oW, oH) {
          var newHeight = $(window).width() * oH / oW;
          return Math.round(newHeight);
        }
      }
})();

EMA.ABOUT = (function() {
  var self;
  var header;
  var currentContentIndex;
  var previousArrow;
  var nextArrow;
  var totalContent;
  var backHeight = 42;
  return {
      init: function(elem) {
        self = this;
        totalContent = $('.content-container .content').length;
        previousArrow = $('.contents-arrow-navigation .arrow-link.previous');
        nextArrow = $('.contents-arrow-navigation .arrow-link.next');
        
        $(window).resize(function() {
          self.resize();
        });
        $(document).ready(function(){
          self.resize();
        })
        

        $('.expertise-nav li a').click(function(e){
          e.preventDefault();
          self.selectContent($(this).parent().index());
        })
        $('.about a.back').click(function(){
          self.closeContents();
        })
        $('.contents-arrow-navigation .arrow-link').click(function(e){
          e.preventDefault();
          self.gotoContent($(this).data("direction"));
        })
        var vid = document.getElementById("header-video-1");
        window.addEventListener('scroll', function() {
          
          var windowHeight = EMA.BUILD.resize().windowHeight*1.5;
          var currentScroll = EMA.BUILD.resize().scrollTop;
          var volumeScroll = (100-(currentScroll* 100 / (windowHeight)))/100;
          if(volumeScroll > 1) {
            volumeScroll = 1;
          }
          if(volumeScroll < 0) {
            volumeScroll = 0;
          }
          // console.log(volumeScroll);
          vid.volume = volumeScroll;
          // console.log(currentScroll > windowHeight);
          if(currentScroll > windowHeight /2) {
            EMA.NAV.showSmall();
          } else {
            EMA.NAV.hide();
          }

        })
        $('.grid').click(function(){
          $(window).off('scroll', self.onScrollGridEventHandler);
          if($(this).hasClass("active")) {
            $(this).removeClass("active");
            
            return;
          } else {
            $('.grid.active').removeClass("active");
            $(this).addClass("active");
            if(Foundation.MediaQuery.is('small down')) {
              $(this).find(".over:not(.bio-details)").css('opacity', 0);
              $(window).on('scroll', { elem: $(this).parent() }, self.onScrollGridEventHandler);
              gsap.to(window, {duration: .4, scrollTo:($(this).parent().offset().top), overwrite: true});
            }
          }
        })
      },resize: function() {
        var siblingGrid;
        $('.grid').each(function(i){
            
          if(!siblingGrid) {
            siblingGrid = $(this);
          }
        })
        if(Foundation.MediaQuery.is('xxlarge down') && Foundation.MediaQuery.is('medium up')){
          $('.grid').each(function(){
            $(this).find('.over:not(.bio-details)').innerWidth($(this).find('img').innerWidth());
            $(this).find('.over:not(.bio-details)').innerHeight($(this).find('img').innerHeight());
          })
          
          var timer = setTimeout(function() {
            console.log("timer")
            $('.grid').each(function(){
              $(this).find('.over:not(.bio-details)').innerWidth($(this).find('img').innerWidth());
              $(this).find('.over:not(.bio-details)').innerHeight($(this).find('img').innerHeight());
            })
            
          }, 2000)
          
          $('.grid').each(function(i){
            
            if(!siblingGrid) {
              siblingGrid = $(this);
            } else {
              $(this).attr("data-sibling-width", siblingGrid.outerWidth());
              if($(this).hasClass("is-even")) {
                $(this).attr("data-sibling-width", -(siblingGrid.outerWidth()-1));
              }
              if(i == $('.grid').length-1 && !$(this).is(':last-child')) {
                $(this).attr("data-sibling-width", (siblingGrid.outerWidth()+1));
              }
            }
            if($(this).data("sibling-width")) {
              $(this).find(".over.bio-details").css("left",$(this).data("sibling-width"));
            } else {
              $(this).find(".over.bio-details").css("left",$(this).outerWidth());
            }  
          })
        }
        
       if(Foundation.MediaQuery.is('xxlarge down') && Foundation.MediaQuery.is('medium up')){
          $('.full-height, .full-height .row, .full-height .row .columns:not(.expertise-nav-container)').each(function(){
            $(this).height($(window).height());
            $(this).css("max-height", $(window).height());
          })
          $('.half-height, .half-height .row, .half-height .row .columns').each(function(){
            $(this).outerHeight($(window).height()/2);
            $(this).css("max-height", $(window).height()/2);
          })
          $('.expertise-nav').height($('.expertise-nav-container').height());
          $('.expertise-nav li').height($('.expertise-nav').height()/4);
        } 
        // $('.expertise-nav').width($(window).width()/2);
        // $('.content-container').width($(window).width()/2);
        $('.content-container .content-container-strip .content').width(($(".content-container").width()+50));
        $('.content-container .content-container-strip').width(($('.content-container .content-container-strip .content').outerWidth(true))*totalContent+1);
        var navArrowsWidth = $(window).width()-($('.contents-arrow-navigation').offset().left+100);
        $('.contents-arrow-navigation').width(navArrowsWidth);
        $(".about h2").each(function(){
          $(this).width($(this).parent().height());
        })
        $(".about a.back").each(function(){
          $(this).width($(this).parent().parent().parent().height());
        })

        if(Foundation.MediaQuery.is('small down')) {
          $('.expertise-nav-container').height($(window).height());
          $('.expertise-nav').height($('.expertise-nav-container').height()-$('.mobile-nav').height());
          $('.expertise-nav li').height($('.expertise-nav').height()/4);
          
          // $('.expertise-nav').width($(window).width());
          $('.content-container').width($(window).width());

          $('.content-container .content-container-strip .content').width(($(window).width()));
          $('.content-container .content-container-strip').width(($('.content-container .content-container-strip .content').outerWidth(true))*totalContent+1);
          $('.contents-arrow-navigation').width("auto");
          $('.contents-arrow-navigation').css("width", "100%");

          $('.grid').each(function(i){
            $(this).attr("data-sibling-width", siblingGrid.outerWidth());
            $(this).find(".description, .contents, .footer").width(siblingGrid.outerWidth()-20);
            $(this).find(".over.bio-details").css("left",0);
          })
          $(".over.bio-details, .over.bio-details .description").css("max-width", $(window).width());
          $(".over.bio-details .contents").css("max-width", $(window).width());
          $(".over.bio-details .contents, .over.bio-details .description").css("width", "auto");
          
          self.manageContentsSize();
        }
      },
      onScrollGridEventHandler: function(e) {
        var elem = e.data.elem;
        if(window.pageYOffset<=(elem.offset().top-($(window).height()/3))|| window.pageYOffset>=(elem.offset().top+($(window).height()/3))){
        // if(window.pageYOffset>=(elem.offset().top-($(window).height()/2)) || window.pageYOffset>=(elem.offset().top+($(window).height()/2))) {
          $(window).off('scroll', self.onScrollGridEventHandler);
          elem.find(".grid").removeClass("active");
        }
      },  
      onScrollEventHandler: function(e) {
        //console.log("onScrollEventHandler", );
        if(Foundation.MediaQuery.is('small down')) {
          if(window.pageYOffset>=($(".team-container").offset().top-($(window).height()/2)) || (window.pageYOffset<$(".our-core-image").offset().top)) {
            $(window).off('scroll', self.onScrollEventHandler);
            self.closeContents();
          }
        }
      },  
      selectContent: function(i) {
        if(Foundation.MediaQuery.is('xxlarge down') && Foundation.MediaQuery.is('medium up')){
          $(".about a.back, .our-core-image, .contents-arrow-navigation").addClass("active");
        }
        $('.content-container').css("left", 0);
        // $('.content-container .content').css("z-index", 0);
        // $('.content-container .content:eq('+i+')').css("z-index", 1);
        $(window).on('scroll', self.onScrollEventHandler);
        
        var newLeft = -($('.content-container .content-container-strip .content').outerWidth(true)*(i));
        currentContentIndex = i;
        $('.content-container-strip').css('left', newLeft);
        previousArrow.show();
        nextArrow.show();

        // MANAGE ARROWS
        previousArrow.show();
        nextArrow.show();
        
        if(currentContentIndex >= totalContent-1) {
          nextArrow.hide();
        } 
        if(currentContentIndex <= 0) {
          previousArrow.hide();
        }
        
        if(Foundation.MediaQuery.is('small down')) {
          $(".about a.back, .contents-arrow-navigation").addClass("active");
          gsap.to(window, {duration: .3, scrollTo:($("#back").offset().top-backHeight), onComplete: function(){
            // $("body").css({"overflow":"hidden",'position':'fixed'});
            document.documentElement.scrollTop = $("#back").offset().top;
            backHeight = 0;
            gsap.to($('.contents-arrow-navigation'), .4, {bottom: 60, ease: Back.easeOut})
          }, overwrite: true});
          
          self.manageContentsSize();
        }
      }, 
      manageContentsSize: function(){
        var backHeight = 42;
        // console.log("manageContentsSize",$(window).height(), window.innerHeight);
        // console.log($(window).height(), $('.mobile-nav').height(), backHeight, $('.contents-arrow-navigation').height());
        if(Foundation.MediaQuery.is('small down')) {
          $('.expertise-nav-container').height(window.innerHeight-($('.mobile-nav').height()+backHeight)+$('.contents-arrow-navigation').height());
        }
      },
      closeContents: function(){
        $(".about a.back, .our-core-image, .contents-arrow-navigation").removeClass("active");
        $('.content-container').css("left", $(window).width());
        if(Foundation.MediaQuery.is('small down')) {
          gsap.to($('.contents-arrow-navigation'), .4, {bottom: -60, ease: Back.easeIn})
        }
      }, 
      gotoContent: function(direction){
       
        previousArrow.show();
        nextArrow.show();

        currentContentIndex += direction;
        if(currentContentIndex >= totalContent-1) {
          //loop
          //currentContentIndex = 0;
          // no loop 
          currentContentIndex = totalContent-1
          // nextArrow.hide();
        } 
        if(currentContentIndex < 0) {
          //loop
          // currentContentIndex = totalContent-1;

          // no loop
          currentContentIndex = 0;
          // previousArrow.hide();
        }
        self.selectContent(currentContentIndex);
        console.log(direction,currentContentIndex, totalContent);
      }
    }
})();
EMA.HEADER = (function() {
    var currentVideo;
    var self;
    var elem;
    var showing;
    var oldShowing;
    var newShowing;
    var currentLoop;
    var videos;
    var vid;
    return {
        init: function(elem) {
          // console.log("header")
          self = this;
          elem = elem;
          // console.log(elem)
          self.resize(elem);
          //window.onload = function() {
            vid = document.getElementById("header-video-1");
          console.log(vid);
            var autoplayrandom = $(elem).data('autoplayrandom');
            
            var hasAudioTrack = $(vid).data('audio');
            vid.addEventListener("loadeddata", function (e) {
              
              console.log("loadeddata");
              
              self.resize(elem);
              if ( !isNaN(hasAudioTrack) && hasAudioTrack > 0 ) {
                this.onplaying = function() {
                console.log("is playing");
                paused = false;
                $(this).parent().find('.video-controls-container .icon-icon-play').removeClass("icon-icon-play" ).addClass("icon-icon-pause");
                var image = $(this).parent().find('.overlay-transparent-image');
                //gsap.to(window, {duration: 2, scrollTo:target, ease: Back.easeInOut});
                gsap.to(image, .2, {opacity: 0, ease: Quad.easeOut});
                };
                this.onended = function() {
                  console.log("is onended");
                  this.load();
                  $(this).parent().find('.video-controls-container .icon-icon-pause').removeClass("icon-icon-pause" ).addClass("icon-icon-play");
                  var image = $(this).parent().find('.overlay-transparent-image');
                  gsap.to(image, .2, {opacity: 1, ease: Quad.easeOut});
                  $('.video-controls-container').fadeIn();

                  paused = true;
                };
                this.onpause = function() {
                  console.log("is onpause");
                  $(this).parent().find('.video-controls-container .icon-icon-pause').removeClass("icon-icon-pause" ).addClass("icon-icon-play");
                  // $(this).parent().find('.overlay-transparent-image').fadeIn();
                  var image = $(this).parent().find('.overlay-transparent-image');
                  gsap.to(image, .2, {opacity: 1, ease: Quad.easeOut});
                  paused = true;
                };
                // console.log("audio track detected",vidID);
                
                this.muted = false;
                this.loop = false;
                this.playsinline = false;
                $(this).removeAttr("playsinline");
                $(this).removeAttr("muted");
                // .webkitEnterFullScreen();
                
              } else {
                if(autoplayrandom) {
                  // var randomVideo = getRandomInt(0, $('.video-fit-fullscreen video').length);
                  // console.log("randomVideo",randomVideo);
                  // currentLoop = randomVideo;
                  
                  self.randomPlay(elem);
                  gsap.to(vid, .2, {opacity:1})
                  // 
                } 
              }
            })
            // vid.addEventListener("error", function (e) {
            
            //   console.log("error",e);
            //   if(autoplayrandom) {
            //     $('.video-fit-fullscreen video:eq(1)').remove();
            //   } 
            // })
          // }
          

          $(window).resize(function() {
              self.resize(elem);
          });
          var paused = true;
          

          function playVid() { 
              vid.play(); 
              $('.video-controls-container .icon-icon-play').removeClass("icon-icon-play" ).addClass("icon-icon-pause");
              hideControls();
          } 

          function pauseVid() { 
              vid.pause(); 
              $('.video-controls-container .icon-icon-pause').removeClass("icon-icon-pause" ).addClass("icon-icon-play");
          }
          function hideControls()
          {
              var timer;
              $(document).on("mousemove", function() {
                  if (timer) {
                      clearTimeout(timer);
                      timer = 0;
                  }
                  $('.video-controls-container').fadeIn();
                  $('.video-fit-fullscreen a.icon-icon-scroll-down').fadeIn();
                  timer = setTimeout(function() {
                    if(!paused) {
                      $('.video-controls-container').fadeOut();
                      $('.video-fit-fullscreen a.icon-icon-scroll-down').fadeOut();
                    }
                  }, 1500)
              })
          }
          var vidHasPLayed = false;

          $("#header-video").bind("ended", function() {
              vid.play();
              
              if(!vidHasPLayed && $(document).scrollTop() == 0) {
                  $('body,html').animate({
                      scrollTop: $(".generic-content-container").offset().top
                  }, 1000, "swing");
                  vidHasPLayed = true;
              }
          });
          $('.video-fit-fullscreen a.icon-icon-scroll-down').on("click", function(){
              vidHasPLayed = true;
              $('body,html').animate({
                scrollTop: $(".generic-content-container").offset().top
              }, 1000, "swing");
          })
          
          $('.video-fit-fullscreen').bind("click", function(){
            if(vid) {
              if (paused) {
                  paused = false;
                  playVid();
              } else {
                  pauseVid();
                  
                  paused = true;
              }
            }
          })
        },
        randomPlay: function(elem) {
          self.resize(elem);
          console.log("randomPlay");
          currentLoop = 1;

          videos = new Array();
          $('.video-fit-fullscreen video').each(function(){
            var id = $(this).attr("id");
            var video = document.getElementById(id);
            videos.push(video);
            console.log(video.duration)
            if(isNaN(video.duration)) {
              $(video).remove();
            }
          })
          


          if(!isNaN(showing)) {
            if(videos.length > 1) {
              oldShowing = showing;
              newShowing = getRandomInt(0,videos.length-1);

              while(newShowing == oldShowing) {
                newShowing = getRandomInt(0,videos.length-1);
              }
              showing = newShowing;
            }
            else {
              newShowing = 0;
            }
            
          } else {
            showing = getRandomInt(0,videos.length-1);
          }
          
          for(var i = 0; i < videos.length; i++) {
          //  videos[i].currentTime = 0;
            if(oldShowing == i) {
              $(videos[i]).animate({opacity: 0}, 1000, self.removeOldVideo);
            }
            if (showing == i) {
              $(videos[i]).animate({opacity: 1}, 1000);
              currentVideo = videos[showing];
            }
          }
          var theme = $(currentVideo).data('theme');
          EMA.HEADER.updateHeaderStyleAccordingVideoTheme(currentVideo, theme);
          currentVideo.currentTime = 0;
          var playPromise = currentVideo.play();
          if (playPromise !== undefined) {
            playPromise.then(_ => {
              // Automatic playback started!
              // Show playing UI.
            })
            .catch(error => {
              // Auto-play was prevented
              // Show paused UI.
            });
          }
          // $(currentVideo).fadeIn();
          currentVideo.addEventListener('timeupdate', self.videoTimeUpdateHandler);
        },
        removeOldVideo: function() {
          // console.log("removeOldVideo");
          var videos = new Array();
          $('.video-fit-fullscreen video').each(function(){
            var id =$(this).attr("id");
            videos.push(document.getElementById(id));
            
          })

          for(var i = 0; i < videos.length; i++) {
            // videos[i].currentTime = 0;
            if (oldShowing == i) {
              videos[i].pause();
            }
          }
        },
        videoTimeUpdateHandler: function(e){
          var loops = $(currentVideo).data("loops");
          var total = Math.round(currentVideo.duration);
          var currentTime = Math.round(currentVideo.currentTime);
          if(loops != "") {
            total = Math.round(currentVideo.duration * loops);
            currentTime = Math.round(currentTime * currentLoop);
          }
          if(currentTime >= Math.round(currentVideo.duration) && loops != "") {
            currentLoop++;
            
          }
          
          if(currentTime >= total) {
            currentVideo.removeEventListener('timeupdate', self.videoTimeUpdateHandler);
            self.randomPlay(elem);
          }
        },
        updateHeaderStyleAccordingVideoTheme: function(elem, theme){
            $(header).removeClass (function (index, className) {
              return (className.match (/(^|\s)theme-\S+/g) || []).join(' ');
            });
            $(header).addClass(theme);
        },
        resize: function(elem) {
            
            $('#header .video-fit-fullscreen video').parent().attr('height',$(window).height());
            $('.fp-scroller').css("min-height",$(window).height());
            var fixedBackToTopRight = ((Math.round($(window).width())-$(".generic-content-container .container .row:first-child").width())/2)-60;
        }
    };
})();

EMA.NAV = (function() {
    var self;
    var elem;
    return {
      init: function(el) {
        self = this;
        elem = el;
        // $(elem).find(".magnet").on('mouseenter', self.showAll);
        // $(elem).find(".magnet").on('mouseleave', self.magnetShow);
        $(elem).on('mouseenter', self.showAll);
        $(elem).on('mouseleave', self.magnetHide);

        //$(elem).on('click', self.showAll);

        $(window).resize(function() {
            self.resize();
        });
        self.resize();
        $('.main-nav .arrow-link').on("click", self.hide);
      },
      showSmall: function() {
        $(elem).addClass("show-small");
      },
      showAll: function() {

          $(elem).removeClass("hidden-no-animation-intro");
          $(elem).addClass("hidden-no-animation");
          $(elem).toggleClass("show-all");
          $(".section").addClass("menu-opened");
          if(!$(elem).hasClass("show-all")) {
            $(".section").removeClass("menu-opened");
          }

      },
      hide: function() {
        $(elem).removeClass("show-small");
        $(".section").removeClass("menu-opened");
      },
      magnetShow: function() {
        $(elem).addClass("magnet-show");
      },
      magnetHide: function() {
        $(elem).removeClass("magnet-show");
        $(elem).removeClass("show-all");
        $(".section").removeClass("menu-opened");
      },resize: function() {
        $(".main-nav").width($(window).height());
        $(".main-nav").css("left", $(window).width()-$(".main-nav").height());
        //console.log($(window).height()+100);

      }
    }
})();
EMA.NAV_MOBILE = (function() {
  var self;
  var elem;
  return {
    init: function(el) {
      self = this;
      elem = el;

      $(elem).find(".lines-container").on('click', self.showAll);
      $(elem).find(".arrow-link").on('click', self.showAll);

      $(window).resize(function() {
          self.resize();
      });
      self.resize();
    },
    showAll: function() {

      if(!$(elem).hasClass("show-all")) {
        $(elem).addClass("show-all");
        // $('.mobile-nav ').css("bottom", 0);
      } else {
        $(elem).removeClass("show-all");
        // $('.mobile-nav ').css("bottom", -($(window).height()-60));
      }

    },
    resize: function(e) {
      $('.mobile-nav ').css("top", "auto");
      $(elem).removeClass("show-all");
      // $('.mobile-nav ').css("bottom", -($(window).height()-60));

    }
  }
})();

EMA.FOOTER = (function() {
  var self;
  var elem;
  var footerHeight = 0;
  var footerTop = 0;
  var $footer;
  return {
    init: function(el) {
      self = this;
      elem = el;
      if($(".footer-container").length) {
        $footer = $(".footer-container");
      } else {
        $footer = $(el);
      }
      
      $(window).resize(function() {
          self.resize();
      });
      self.resize();

      // $(elem).click(function(){
      //   $(elem).toggleClass("white");
      // })
    },
    setWhite: function(){
      console.log("footer white")
      $(elem).addClass("white");
    },
    setBlack: function(){
      $(elem).removeClass("white");
    }, 
    resize: function(){
      if(Foundation.MediaQuery.is('medium down')) {
        // $("#fullpage, main").css("padding-bottom", $(elem).outerHeight());
        // $("#fullpage, main").css("margin-bottom", $(elem).outerHeight());
      }
      self.positionFooter();
    },
    positionFooter: function() {
      footerHeight = $footer.height();
      footerTop = ($(window).scrollTop()+$(window).height()-footerHeight)+"px";
      $footer.css({
        position: "absolute",
        top: "100%"
      })
      
      
      gsap.to($footer, .5,{onComplete: function(){
        footerTop = ($(window).scrollTop()+$(window).height()-footerHeight)+"px";
        console.log("positionFooter",($("body").height()+footerHeight) < $(window).height(), ($("body").height()+footerHeight), $(window).height(), $("body").innerHeight());
        if ( ($("main").height()+footerHeight) < $(window).height()) {
          $footer.css({
            position: "absolute"
          })
          gsap.to($footer, .5,{top: footerTop, ease: Quad.easeOut});
        } else {
          
          $footer.css({
            position: "static"
          })
          if(Foundation.MediaQuery.is('small down')) {
            $footer.css({
              "margin-bottom": $(".mobile-nav").height()
            })
          }
          

        }
      }}); 
    }
  }
})();
EMA.CONTACTS = (function() {
  var self;
  var elem
  return {
    init: function(el) {
      self = this;
      elem = el;

      $(".privacy-link, .close").click(function(){
        $(".privacy-policy").toggleClass("opened");
      })
    }
  }
})();
$(function() {
    // Foudation
    $(document).foundation();
    //console.log("animation()")
    // Call when document is ready
    EMA.BUILD.init();

    $('.zoom-animate').addClass('animatable');

    setTimeout(function() {
        $('.zoom-animate').removeClass('animatable');
    }, 4000)

    $('.parallax').parallaxElement({
      defaultSpeed:  0.08,   // Integer - Default speed if `data-speed` is not present
      useOffset:     true,  // Boolean - Whether or not to start animations below bottom of viewport
      defaultOffset: 100,   // Distance before element appears to start animation
      disableMobile: false, // Boolean - allow function to run on mobile devices
      minWidth:      false  // Integer - minimum width the function should fire
    });

    $(".main-nav").width($(window).height());
    $(".main-nav").css("left", $(window).width()-$(".main-nav").height());

    $('.container.full-height').each(function(){
      if($(this).height() < $(window).height()) {
        $(this).height($(window).height());
      }
    })

    var animation = function() {

        var elems,
            windowHeight;

        var init = function() {
            elems = document.getElementsByClassName('animation-do')
            windowHeight = window.innerHeight
            _addEventHandlers()
        }

        var _addEventHandlers = function() {
            window.addEventListener('scroll', _checkPosition)
            window.addEventListener('resize', init)
        }

        var _checkPosition = function() {

            for (var i = 0; i < elems.length; i++) {
                var posFromTop = elems[i].getBoundingClientRect().top;
                //console.log("posFromTop",posFromTop);
                if (posFromTop - windowHeight <= 0) {
                    elems[i].className = elems[i].className.replace('animation-do', 'element-animate')
                }
            }
        }

        return {
            init: init
        }
    }
    $(document).ready(function(){
      $(this).scrollTop(0);
      animation().init()
    })


});
